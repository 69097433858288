
/*
 *   本文件为接口配置列表
 */ 

var global = window.apiconfig || {};
var conn = {};
var v = '';
// 配置接口
conn.configMap = {
			
			user:{
				// GET   1.0.0/getFirstLoginInfo 有返回内容 表示要弹屏没有就不弹屏
				getFirstLoginInfo:{ url: global.apiurl + "/1.0.0/getFirstLoginInfo",method: "get"},
				
				// etcl-h5 费控单点登录接口  /1.0.0/etcl/login
				etclLogin:{ url: global.apiurl + "/1.0.0/etcl/login",method: "get"},
                // 单点登录，请勿升级到1.1.0
                ssologin:{ url: global.apiurl + "/1.0.0/vipLogin", method : "jsonp",noNeedToken:true },				
				
				// 登录
				login:{ url: global.apiurl + "/1.1.0/vipLogin", method : "jsonp",noNeedToken:true ,timeout:40000},
				
				// 登出
				loginOut:{url: global.apiurl + "/1.0.0/vipLoginOut", method : "jsonp"},
				
				//获取企业列表
				getCorpList:{url: global.apiurl + "/1.0.0/getCorpList", method: "get"},
				
				// 员工信息
				userInfo:{url: global.apiurl + "/1.0.0/getLoginUserInfo", method : "get"},
				
				//企业员工修改个人信息 /1.0.0/vipEmpEditInfo
				editPersonInfo:{url: global.apiurl + "/1.0.0/vipEmpEditInfo", method : "jsonp"},
				
				// 员工信息编辑
				editInfo:{url: global.apiurl + "/1.0.0/vipEmpEditInfoObt", method : "jsonp"},
				
				// 常用联系人列表
				contactsGet:{url: global.apiurl + "/1.0.0/vipEmpContactsGetList", method : "GET"},
				
				// 常用地址列表
				addrGet:{url: global.apiurl + "/1.0.0/vipEmpAddrGetList", method : "GET"},
				
				// 常用联系人编辑
				contactsEdit:{url: global.apiurl + "/1.0.0/vipEmpContactsEdit", method : "jsonp"},
				
				//修改密码保存
				savePwd:{url: global.apiurl + "/1.0.0/vipEmpEditPwd", method : "jsonp"},
				
				// 常用地址编辑
				addrEdit:{url: global.apiurl + "/1.0.0/vipEmpAddrEdit", method : "jsonp"},
				
				// 常用联系人删除
				contactsDel:{url: global.apiurl + "/1.0.0/vipEmpContactsDel", method : "GET"},
				
				// 常用联系人删除  POST 
				addrDel:{url: global.apiurl + "/1.0.0/vipEmpAddrDel", method : "GET"},
				
				//获取员工信息（个人中心）
				EmpGetInfo:{url: global.apiurl + "/1.0.0/vipEmpGetInfo", method : "GET"},
				
				// 获取验证码
				codeGet:{url: global.apiurl + "/1.0.0/smsCodeGet", method : "GET",noNeedToken:true },
				
				// 验证码校验GET  
				codeCheck:{url: global.apiurl + "/1.0.0/smsCodeCheck", method : "GET"},
				
				// 手机绑定
				phoneBind:{url: global.apiurl + "/1.0.0/phoneBinding", method : "jsonp",noNeedToken:true},
				
				// 手机解绑 POST 
				phoneUnbind:{url: global.apiurl + "/1.0.0/phoneUnbind", method : "jsonp"},
				
				// 修改密码 验证  
				resetPassword:{url: global.apiurl + "/1.0.0/passwordRetake", method : "GET"},
				
				// 新密码修改
				ResetPwd:{url: global.apiurl + "/1.0.0/vipEmpResetPwd", method : "jsonp"},
				 
				///2.0.0/vipEmpResetPwd
				resetPwdTwo:{url: global.apiurl + "/2.0.0/vipEmpResetPwd", method : "jsonp"}, 
				// 待我审批  POST （需要我审批）
				myApp:{url: global.apiurl + "/1.0.0/vipBpmForMyApp", method : "GET"},
				
				// 我已审批  POST 
				myApped:{url: global.apiurl + "/1.0.0/vipBpmForMyApped", method : "GET"},
                	//  待我审批  我已审批 total  POST 
                approvalTotal:{url: global.apiurl + "/1.0.0/vipBpmForMyAppCount", method : "GET"},
				
				// 待我审批条数 单独
				vipBpmForMyAppTotal:{url: global.apiurl + "/1.0.0/vipBpmForMyAppTotal", method : "GET"},
				
				//企业注册
				saveVipCorpApply:{url: global.apiurl + "/1.0.0/saveVipCorpApply", method : "jsonp",noNeedToken:true},
				//个人注册
				userRegister:{url: global.apiurl + "/1.0.0/userRegister", method : "jsonp",noNeedToken:true},
				//个人注册 获取验证码
				getRegisterSmsCode:{url: global.apiurl + "/1.0.0/getRegisterSmsCode", method : "get",noNeedToken:true},
				///1.0.0/validatePwd 验证登录人密码是否正确
				getValidateCode:{url: global.apiurl + "/1.0.0/getValidateCode", method : "get"},
				
				//POST /1.0.0/modifyPhoneNumber
				//修改手机号码
				modifyPhoneNumber:{url: global.apiurl + "/1.0.0/modifyPhoneNumber", method : "jsonp"},
				
				//POST /1.0.0/validatePwd
				//验证登录用户的密码
				
				validatePwd:{url: global.apiurl + "/1.0.0/validatePwd", method : "jsonp"},
				
				//
				hasSamePhone:{url: global.apiurl + "/1.0.0/hasSamePhone", method : "get"},
				//查询常用联系人
				findContactsByEmpId: {url: global.apiurl + "/1.0.0/findContactsByEmpId",method: "jsonp"},
				//POST /1.0.0/saveContacts常用联系人新增和编辑
				saveContacts: {url: global.apiurl + "/1.0.0/saveContacts",method: "jsonp"},
				//删除常用联系人
				delContacts:{url: global.apiurl + "/1.0.0/delContacts",method: "jsonp"},
				// 1.0.0/getReceiptList获取当前用户发票列表
				getReceiptList:{url: global.apiurl + "/1.0.0/getReceiptList",method: "get"},
				// /1.0.0/saveReceipt 
				saveReceipt:{url: global.apiurl + "/1.0.0/saveReceipt",method: "jsonp"},
				///1.0.0/deleteReceipt删除发票信息
				deleteReceipt:{url: global.apiurl + "/1.0.0/deleteReceipt",method: "get"},
				//1.0.0/getReceiptDetail获取当前用户发票列表
				getReceiptDetail:{url: global.apiurl + "/1.0.0/getReceiptDetail",method: "get"},

				// 1.0.0/tbea/sso   携程和空港单点
				tbeaSso:{url: global.apiurl + "/1.0.0/tbea/sso",method: "jsonp",noNeedToken:true},
				// 获取企业参数
				getVipParameter:{url: global.apiurl + "/1.0.0/getVipParameter",method: "jsonp"},
			},
			
			commu:{//公共插件    POST 
				getVipParamterList:{ url: global.apiurl + "/1.0.0/requiredVipParamterList",method : "jsonp"},
				// 获取企业参数 get
				getVipCorpParameter:{ url: global.apiurl + "/1.0.0/getVipCorpParameter",method : "get"},
				// GET 差旅政策管理
				vipControlDetail:{url: global.apiurl + "/1.0.0/vipControlDetail",method: "get"},
				// 获取差旅政策   /1.0.0/getUserTravelInfo
				getUserTravelInfo:{url: global.apiurl + "/1.0.0/getUserTravelInfo",method: "get"},
				//审批进度/审批跟踪
				appProgress:{ url: global.apiurl + "/1.0.0/vipOrderAppProgress",method : "jsonp"},
				//获取验证码
				smsCodeGet:{ url: global.apiurl + "/1.0.0/smsCodeGet",method : "GET",noNeedToken:true},
				//校验验证码
				smsCodeCheck:{ url: global.apiurl + "/1.0.0/smsCodeCheck",method : "GET"},
				//匹配审批规则(单订)
				singleModelMatchSpgz:{ url: global.apiurl + "/1.0.0/singleModelMatchSpgz",method : "jsonp"},
				//匹配审批规则(出差单)
				travelRouteMatchSpgz:{ url: global.apiurl + "/1.0.0/travelRouteMatchSpgz",method : "jsonp"},
				//获取审批规则列表
				spgzList:{ url: global.apiurl + "/1.0.0/spgzFindList",method : "get"},
				//送审
				sendApp:{ url: global.apiurl + "/1.0.0/vipBpmSendApp",method : "get"},
				// GET /1.0.0/vipBpmSendAppCancel 申请单撤回
				vipBpmSendAppCancel:{url:global.apiurl + "/1.0.0/vipBpmSendAppCancel", method:"get"},
				//获取bclass参数
				getBClass:{ url: global.apiurl + "/1.0.0/getBClass",method : "get",async : false,noNeedToken:true},
				//审批
				myAppDetail:{url: global.apiurl + "/1.0.0/vipBpmReceiveAppResult" ,method:"jsonp"},
				//审批规则详情
				appRulesDetail:{url: global.apiurl + "/1.0.0/vipSpgzGetInfo" ,method:"get"},
				//获取代订范围
				empFindList:{url: global.apiurl + "/1.0.0/empFindList" ,method:"get"},
				//待订范围(新版)
				empFindList3:{url: global.apiurl + "/3.0.0/empFindList" ,method:"get"},
				//根据部门id查该部门下的员工列表
				deptEmpFindList:{url: global.apiurl + "/1.0.0/deptEmpFindList" ,method:"jsonp"},
				//根据预订人范围获取员工列表
				empBookRange:{url: global.apiurl + "/1.0.0/findEmpListByBookRange" ,method:"get"}, 
				//查询审批规则设置
				vipSpkzGetInfo:{url:global.apiurl + "/1.0.0/vipSpkzGetInfo" ,method:"get"},
				
				//根据预订人获取审批规则列表
				appRule:{url: global.apiurl + "/1.0.0/spgzFindList" ,method:"get"}, 
				//根据预订人获取成本中心列表
				costCenterList:{url: global.apiurl + "/1.0.0/costCenterFindList" ,method:"jsonp"}, 
				costCenterList2:{url: global.apiurl + "/2.0.0/costCenterFindList" ,method:"jsonp"}, 
				costCenterList3:{url: global.apiurl + "/3.0.0/costCenterFindList" ,method:"jsonp"}, 
				//根据获取项目列表
				//project:{url: global.apiurl + "/1.0.0/projectList" ,method:"jsonp"}, 
				project:{url: global.apiurl + "/2.0.0/projectList" ,method:"jsonp"}, 
				//国家，身份，城市数据
				baseData : {url:global.jsurl +"/static/js/proCity.js", method:"get",async:true,noNeedToken:true},
				//根据id获取最低的员工职级(不输入直接获取企业下最低职级)
				getRank : {url:global.apiurl +"/1.0.0/getRankByEmpIds", method:"jsonp",async:true},
				
				//获取企业参数
				parameter : {url:global.apiurl + "/1.0.0/getParameter", method:"get", async : false},
				//获取参数
				requiredVipParamterList : {url:global.apiurl + "/1.0.0/requiredVipParamterList", method:"post"},
				//获取服务费
				fee : {url:global.apiurl +"/1.0.0/vipFeeSetDetail", method:"jsonp"},
				
				//获取申请列表
				vipBpmList : {url:global.apiurl +"/1.0.0/vipBpmList", method:"jsonp"},
				
				//POST /1.0.0/etPageSetList页面风格设置et查询
				etPageSetList:{url:global.apiurl +"/1.0.0/etPageSetList", method:"jsonp", async : false,noNeedToken:true},
				
				// GET /1.0.0/violateReasonList 违背原因控件
				violateReasonList: {
					url: global.apiurl + "/1.0.0/violateReasonList",
					method: "get"
				},
				projectFindList:{url: global.apiurl + "/2.0.0/projectList", method:"jsonp"}, 
				
				///1.0.0/getBParam
				getBParam:{url: global.apiurl + "/1.0.0/getBParam", method:"get"}, 
				
			},
			common: {
				//图片上传 （违背原因）
				upload: {
				  	url: global.stfurl + "/file/web/upload",
					method: "post"
				},
				//图片上传 头像
				// base64Upload: {
				//     url: global.apiurl + "/1.0.0/uploadAvatar",
				// 	method: "jsonp"
				// },
			},
			staticData :{
				// 国内机票城市  含hot城市，但A-Z不准   如 a中的安阳
				city : { url: global.jsurl + "/static/js/city.js?_=" + v,	method : "get"},
				// 国内机票城市  A-Z
				cityAirportForPx : { url: global.jsurl + "/static/js/cityAirportForPx.js?_=" + v,	method : "get"},
				cityAirport : { url: global.jsurl + "/static/js/cityAirport.js?_=" + v,	method : "get"},
				proCity:{url:global.jsurl + "/static/js/proCity.js?_=" + v, method:"get"},
				bcity : { url: global.jsurl + "/static/js/bcity.js?_=" + v,	method : "get"},
				cityStation : { url: global.jsurl + "/static/js/cityStation.js?_=" + v,	method : "get"},
				trainCity : { url: global.jsurl + "/static/js/trainCity.js?_=" + v,	method : "get"},
				// 国际    机场城市
				getGjAirportData:{ url: global.apiurl + "/1.0.0/getGjAirportData", method : "jsonp" },
				// 国际    城市
				getGjCityData:{ url: global.apiurl + "/1.0.0/getGjCityData", method : "jsonp" },
				//行政区、商圈、标志物
				geoLocation : {url:global.apiurl + "/1.0.0/geocoder/cityLocation", method:"get"},
				//关键词搜索poi
				poiPlace : {url:global.apiurl + "/1.0.0/geocoder/poiPlace", method:"get"},
				//火车站热门城市Js
				cityStationH5: {
					url: global.jsurl + '/static/js/cityStationH5.js',
					method: "get",
				},
				//火车站城市版本号
				trainZdVersion: {
				  url: global.apiurl + "/1.0.0/trainZdVersion",
				  method: "jsonp",
				},
			},
			costCenter:{
				
				//查询关联部门树,根据成本中心id所关联部门,在树中添加已选标记POST /1.0.0/vipCostcenterDeptTree
				vipCostcenterEmpDeptTree:{url: global.apiurl+"/1.0.0/vipCostcenterDeptTree",method:"get"},
				vipCostcenterFindDetail:{url: global.apiurl+"/1.0.0/vipCostcenterFindDetail",method:"get"},
			},
			change:{
				//机票申请改签
				applyChange:{ url: global.apiurl + "/1.0.0/applyTkChange", method : "get" },
				//改签航班查询
//				queryFlight:{ url: global.apiurl + "/1.0.0/tkChangeQueryFlight", method : "jsonp" },
				queryFlight:{ url: global.apiurl + "/2.0.0/tkChangeQueryFlight", method : "jsonp" },
				//改签单详细
				tkChangeOrderDetail:{ url: global.apiurl + "/1.0.0/tkChangeOrderDetail", method : "get", async : false },
				//改签提交
				tkChangeOrderAdd:{ url: global.apiurl + "/1.0.0/tkChangeOrderAdd", method : "jsonp" },
				//改签提交2.0   tkChangeOrderAdd
				tkChangeOrderAdd2: {url: global.apiurl + "/2.0.0/tkChangeOrderAdd",method: "jsonp"},
				//根据订单id获取改签单集合
				findByOrderId:{ url: global.apiurl + "/1.0.0/findChangeOrderByOrderId", method : "get" },
				//根据改签单id获取改签单集合
				findByChangeId:{ url: global.apiurl + "/1.0.0/findChangeOrderByChangeId", method : "get" },
				//取消改签单
				cancelChangeOrder:{ url: global.apiurl + "/1.0.0/cancelChangeOrder", method : "get" },
				//自动改签费用
				preChange:{url: global.apiurl + "/1.0.0/preChange",method: "jsonp"},
				
			},
			pay:{
				//获取支付列表
				getPaySubject:{ url: global.apiurl + "/1.0.0/getPaySubject", method : "jsonp" },
				//在线支付
				onlinePay:{ url: global.apiurl + "/1.0.0/onlinePay", method : "jsonp" },
				//0元支付
				zeroPayment:{ url: global.apiurl + "/1.0.0/zeroPayment", method : "jsonp" },
				//根据流水号获取支付订单信息
				getPayOrderIdList:{ url: global.apiurl + "/1.0.0/getPayOrderIdList", method : "get" },
				//支付状态
				queryOnlinePayStatus : { url: global.apiurl + "/1.0.0/queryOnlinePayStatus", method : "get" },
				//修改服务费
				modifyServiceFee:{ url: global.apiurl + "/1.0.0/modifyServiceFee", method : "jsonp" }
			},
			tkOrder:{
				//验证舱位价格
                ValidateCabinPrice:{ url: global.apiurl + "/1.0.0/ValidateCabinPrice", method : "jsonp" },
				//全部订单
				totalOrderList:{ url: global.apiurl + "/2.0.0/totalOrderList", method : "jsonp" },
				//机票订单
				tkOrderList:{ url: global.apiurl + "/1.0.0/vipOrderList", method : "jsonp" },
				//机票订单详    /1.0.0/vipClzcTkGetInfo
				tkOrderDetail:{ url: global.apiurl + "/1.0.0/orderDetail", method : "get", async : false },
				// 机票订单详 英文
				orderDetailEnglish:{ url: global.apiurl + "/1.0.0/orderDetailEnglish", method : "get", async : false },
				//新版行程信息英文
				englishItinerary:{ url: global.apiurl + "/1.0.0/englishItinerary", method : "jsonp"},
				//新版行程信息中文
				chineseItinerary:{ url: global.apiurl + "/1.0.0/chineseItinerary", method : "jsonp"},
				// POST /1.0.0/chinsesdownLoad 中文下载接口
				chinsesdownLoad:{ url: global.apiurl + "/1.0.0/chinsesdownLoad", method : "jsonp"},
				// POST /1.0.0/englishdownLoad 英文下载接口
				englishdownLoad:{ url: global.apiurl + "/1.0.0/englishdownLoad", method : "jsonp"},
				//取消订单
				cancelTkOrder:{ url: global.apiurl + "/1.0.0/cancelTkOrder", method : "jsonp" },
				
				//根据订单id,查询订单的改签信息
				changeOrderDetailByOrderId:{ url: global.apiurl + "/1.0.0/findChangeOrderByOrderId",method:"get"},
				//根据机票订单id获取关联的机场服务订单
				getUnionOrder:{ url: global.apiurl + "/1.0.0/getUnionOrder",method:"get"},
				
				//机票预订后增值服务
				findAirportService:{ url: global.apiurl + "/1.0.0/findAirportService", method : "jsonp" },
				//火车票订单取消
				trOrderCancel : { url: global.apiurl + "/1.0.0/trOrderCancel",method:"get"},
				//增值服务下单
				bookAddService : { url: global.apiurl + "/1.0.0/bookAddService",method:"jsonp"},
			},
			
			travelTell:{
				// 行程提醒   POST 
				dcxOrderList:{ url: global.apiurl + "/1.0.0/totalOrderList", method : "jsonp" },
			},
			
			travelZc:{
				
				//差旅政策机票展示（机票 国内 国际）
				tkZcGet:{ url: global.apiurl + "/1.0.0/vipClzcTkGetInfo", method : "GET" },
				
				//差旅政策展示（酒店 火车票）
				hcJdZcGet:{ url: global.apiurl + "/1.0.0/vipClzcHtTrGetInfo", method : "GET" },
				
			},
			
			xywsp:{
			
				// 需要我审批 
				xywspGet:{ url: global.apiurl + "/1.0.0/vipBpmForMyApp", method : "GET" },
			},
			
			
			tkRefund:{
				//申请退票
				applyRefund:{ url: global.apiurl + "/1.0.0/applyRefund", method : "get" },
				
				//退票提交
				refundAdd:{ url: global.apiurl + "/1.0.0/refundAdd", method : "jsonp" },
				
				// 提交申请退票2.0   refundAdd
				refundAdd2: {url: global.apiurl + "/2.0.0/refundAdd",method: "jsonp"},
				
				// 获取退票的服务费   queryRefundFee
				queryRefundFee: {url: global.apiurl + "/1.0.0/queryRefundFee",method: "jsonp"},
				
				// 提交申请退票2.1   refundAdd
				refundAddTwo: {url: global.apiurl + "/2.1.0/refundAdd",method: "jsonp"},
				
				//取消退票
				cancelRefund:{ url: global.apiurl + "/1.0.0/refundCancel", method : "get" },
				
				//退票订单详
				refundDetail:{ url: global.apiurl + "/1.0.0/refundOrderDetail", method : "get",async : false},
				
				//根据订单id,获取相关退票的详细信息
				moreRefundInfo:{ url: global.apiurl + "/1.0.0/moreRefundInfo", method : "get" },
			},
			
			bClass:{
				//获取退票原因
				getBasicData:{ url:global.apiurl + "/1.0.0/getBClass", method : "get" },
			},
			
			travelRequest:{//出差申请+变更
				// 接口名称：/1.0.0/findVipCcsqdList
				findVipCcsqdList:{url:global.apiurl + "/1.0.0/findVipCcsqdList", method:"get"},
				// 接口名称：/1.0.0/findVipCcsqdListExport
				findVipCcsqdListExport:{url:global.apiurl +"/1.0.0/findVipCcsqdListExport", method:"jsonp", async : false},
				//出差申请单列表
				travelRequestList:{url:global.apiurl + "/1.0.0/vipCcsqdGetList", method:"get"},
				//出差申请单详情
				travelRequestInfo:{url:global.apiurl + "/1.0.0/vipCcsqdGetInfo", method:"get"},
				//出差申请单新增/修改
				travelRequestAdd:{url:global.apiurl + "/1.0.0/vipCcsqdAdd", method:"jsonp"},
				// 出差申请单列表
				ccsqdGet:{url: global.apiurl + "/1.0.0/vipCcsqdGetList", method : "GET"},
				//出差申请单删除
				travelRequestDel:{url:global.apiurl + "/1.0.0/vipCcsqdDel", method:"get"},
				//变更单详情
				travelChangeInfo:{url:global.apiurl + "/1.0.0/vipChangeGetInfo", method:"get"},
				//新增变更单时获取回显数据
				changeGetByCcsqd:{url:global.apiurl + "/1.0.0/vipChangeGetByCcsqd", method:"get"},
				//新版H5获取出差单列表接口
				vipCcsqdGetList:{url:global.apiurl + "/1.0.0/vipCcsqdGetList", method:"get"},
				//在预订时，员工选择已经审批通过的出差申请单进行预订
				vipCcsqdGetListForChoose:{url:global.apiurl + "/1.0.0/vipCcsqdGetListForChoose", method:"get"},
				//变更单新增/修改
				travelChangeAdd:{url:global.apiurl + "/1.0.0/vipChangeAdd", method:"jsonp"},
				//出差申请单删除
				travelChangeDel:{url:global.apiurl + "/1.0.0/vipChangeDel", method:"get"},
				// 费控系统出差申请单同步
				travelApplySync:{url:global.apiurl + "/1.0.0/etcl/travelApplySync", method:"jsonp"},
				// 出差申请单导出列表
				findTaskList:{url:global.apiurl +"/1.0.0/findTaskList", method:"jsonp", async : false},
				findTaskList2:{url:global.apiurl +"/2.0.0/findTaskList", method:"jsonp", async : false, noNeedToken: true},
				// 出差申请单导出
				vipCcsqdGetListExport:{url:global.apiurl +"/1.0.0/vipCcsqdGetListExport", method:"jsonp", async : false},
				
				
				
			},
			tianTravel:{//天润出差申请单
				//出差申请单列表
				travelRequestList:{url:global.apiurl + "/1.0.0/tianrun/vipTravelGetList", method:"get"},
				//出差申请单详情
				travelRequestInfo:{url:global.apiurl + "/1.0.0/tianrun/vipTravelGetInfo", method:"get"},
				//申请单控件
				travelChooseList : {url:global.apiurl + "/1.0.0/tianrun/travelChooseList", method:"jsonp"},
				
			},
			// 机票查询预订2023改版升级
			tkQuery2023:{
				// 企业端机票查询预订 : E Flt Controller显示/隐藏显示操作展开操作
				// POST /1.0.0/et/getOpenTicket查询挂票
				getOpenTicket:{
					url: global.planeurl + "/1.0.0/et/getOpenTicket",
					method: "jsonp"
				},
				// POST /1.0.0/et/queryFlightCabin国内机票
				queryFlightCabin:{
					url: global.planeurl + "/1.0.0/et/queryFlightCabin",
					method: "jsonp"
				},
				// POST /1.0.0/et/querySameOrder查询重复订单
				querySameOrder:{
					url: global.planeurl + "/1.0.0/et/querySameOrder",
					method: "jsonp"
				},
				// 重复校验接口： 1.0.0/et/queryRepeatedItinerary
				queryRepeatedItinerary:{
					url: global.planeurl + "/1.0.0/et/queryRepeatedItinerary",
					method: "jsonp"
				},
				// POST /1.0.0/et/queryTransferDetail查询中转航班
				queryTransferDetail:{
					url: global.planeurl + "/1.0.0/et/queryTransferDetail",
					method: "jsonp"
				},
				// POST /1.0.0/et/queryTransferFlt查询中转航班
				queryTransferFlt:{
					url: global.planeurl + "/1.0.0/et/queryTransferFlt",
					method: "jsonp"
				},
				// POST /3.0.0/et/queryFlight国内机票
				queryFlight3:{
					url: global.planeurl + "/3.0.0/et/queryFlight",
					method: "jsonp"
				},
				// POST /4.0.0/et/queryFlight国内机票
				queryFlight4:{
					url: global.planeurl + "/4.0.0/et/queryFlight",
					method: "jsonp"
				},
				// POST /1.0.0/et/matchClbz匹配差旅标准
				matchClbz:{
					url: global.planeurl + "/1.0.0/et/matchClbz",
					method: "jsonp"
				},
				// POST /1.0.0/et/matchTsClbz机票匹配差旅标准
				matchTsClbz:{
					url: global.planeurl + "/1.0.0/et/matchTsClbz",
					method: "jsonp"
				},
				// 获取行李额 POST /1.0.0/et/queryBaggage国内机票免费行李额查询
				queryBaggage:{
					url: global.planeurl + "/1.0.0/et/queryBaggage",
					method: "jsonp"
				},
				// 机票订单操作 : E Order Op Controller显示/隐藏显示操作展开操作
				// POST /1.0.0/et/createOrder国内机票
				createOrder:{
					url: global.planeurl + "/1.0.0/et/createOrder",
					method: "jsonp",
					timeout:90000,
				},
				// POST /1.0.0/et/orderSendBmp  订单送审
				orderSendBmp:{
					url: global.planeurl + "/1.0.0/et/orderSendBmp",
					method: "jsonp"
				},
				// POST /2.0.0/et/tkOrderCancel取消订单
				tkOrderCancel2:{
					url: global.planeurl + "/2.0.0/et/tkOrderCancel",
					method: "jsonp"
				},
				// 机票订单查询 : E Order Qry Controller显示/隐藏显示操作展开操作
				// GET /1.0.0/et/findChangeOrderByOrderId根据订单id获取改签单
				findChangeOrderByOrderId:{
					url: global.planeurl + "/1.0.0/et/findChangeOrderByOrderId",
					method: "get"
				},
				// GET /1.0.0/et/moreRefundInfo订单详里获取退票信息
				moreRefundInfo:{
					url: global.planeurl + "/1.0.0/et/moreRefundInfo",
					method: "get"
				},
				// GET /3.0.0/et/orderDetail机票订单
				orderDetail3:{
					url: global.planeurl + "/3.0.0/et/orderDetail",
					method: "get"
				},
				// POST /1.0.0/et/validCabin国内机票实时舱位验证
				validCabin:{
					url: global.planeurl + "/1.0.0/et/validCabin",
					method: "jsonp"
				},
				// POST /1.0.0/et/queryFlightStop  查询航班经停信息
				queryFlightStop:{
					url: global.planeurl + "/1.0.0/et/queryFlightStop",
					method: "jsonp"
				},
				// POST /1.0.0/et/recomFlight  推荐航班
				recomFlight:{
					url: global.planeurl + "/1.0.0/et/recomFlight",
					method: "jsonp"
				},
				
				// 添加机票改签改版操作
				// 改签模块
				
				// /4.0.0/et/tkChangeApply  获取可改签的航段和乘机人
				tkChangeApply4:{
					url: global.planeurl + "/4.0.0/et/tkChangeApply",
					method: "get"
				},
				// /2.0.0/et/tkChangeQueryFlight  改签航班查询
				tkChangeQueryFlight2:{
					url: global.planeurl + "/2.0.0/et/tkChangeQueryFlight",
					method: "jsonp"
				},
				// 创建编码并且计算改签费用  /1.0.0/et/preChange  这里如果成功，会创建新编码，下单的时候回传
				preChange:{
					url: global.planeurl + "/1.0.0/et/preChange",
					method: "jsonp"
				},
				// 提交改签订单  /2.0.0/et/tkChangeOrderAdd
				tkChangeOrderAdd2:{
					url: global.planeurl + "/2.0.0/et/tkChangeOrderAdd",
					method: "jsonp"
				},
				// 取消改签  /1.0.0/et/changeCancel
				changeCancel:{
					url: global.planeurl + "/1.0.0/et/changeCancel",
					method: "jsonp"
				},
				// 改签订单详情  GET /3.0.0/et/tkChangeDetail 改签订单详情
				tkChangeDetail3:{
					url: global.planeurl + "/3.0.0/et/tkChangeDetail",
					method: "get"
				},
				// 退票改版模块
				// POST /1.0.0/et/refundCancel取消退票
				refundCancel:{
					url: global.planeurl + "/1.0.0/et/refundCancel",
					method: "jsonp"
				},
				// POST /1.0.0/et/queryRefundFee查询退票费
				queryRefundFee:{
					url: global.planeurl + "/1.0.0/et/queryRefundFee",
					method: "jsonp"
				},
				// POST /1.0.0/et/refundApply退票申请页面可退机票查询
				refundApply:{
					url: global.planeurl + "/1.0.0/et/refundApply",
					method: "jsonp"
				},
				// GET /2.0.0/et/refundDetail退票订单详情
				refundDetail2:{
					url: global.planeurl + "/2.0.0/et/refundDetail",
					method: "get"
				},
				// 生成退单 POST /1.0.0/et/createRefund退票订单生成
				createRefund:{
					url: global.planeurl + "/1.0.0/et/createRefund",
					method: "jsonp"
				},
				
			},
			//国内机票接口
			tkBook : {
				//价格日历接口
				queryPriceCalendar:{url: global.apiurl + "/1.0.0/queryPriceCalendar",method: "jsonp",},
				// 获取当前座位喜好列表
				getTkOrderPsgSeatList:{url: global.apiurl + "/1.0.0/getTkOrderPsgSeatList",method: "get",},
				// 航班查询
		      	queryFlight : {url:global.apiurl + "/2.0.0/queryFlight", method:"jsonp", async : true},
				//匹配差旅政策
				matchClbz : {url:global.apiurl + "/1.0.0/matchClbz", method:"jsonp"},
				//匹配差旅政策
				matchClbz2 : {url:global.apiurl + "/2.0.0/matchClbz", method:"jsonp"},
				// 获取当前差旅人的员工信息
				getVipEmpBookScopeDetail:{url: global.apiurl + "/1.0.0/getVipEmpBookScopeDetail",method: "get"},
				//findAirportOpenService
				findAirportOpenService:{url:global.apiurl + "/1.0.0/findAirportOpenService", method:"jsonp"},
				//飞机经停
				flightStop : {url:global.apiurl + "/1.0.0/queryFlightStop", method:"jsonp"},
				// /1.0.0/cancelPnr   取消pnr
				cancelPnr: {url: global.apiurl + "/1.0.0/cancelPnr",method: "jsonp"},
				createPnr:{ url: global.apiurl + "/1.0.0/createPnr", method : "jsonp" },
				// 身份证号验证
				validateIdCard:{ url: global.apiurl + "/1.0.0/validateIdCard", method : "jsonp" },
				//根据员工职级获取差旅标准
				getClzbByMinZj : {url:global.apiurl + "/1.0.0/GetClzbByMinZj", method:"jsonp"},
				//前后两小时推荐航班
				recommendFlight : {url:global.apiurl + "/1.0.0/recommendFlight", method:"get", async : false},
				//同步获取bclass数据
				bClass : {url:global.apiurl + "/1.0.0/getBClass", method:"get", async : false},
				//预订下单2.0
				bookOrder2:{url:global.apiurl + "/2.0.0/bookOrder",method:"jsonp",async : false},
				//预订下单
				bookOrder:{url:global.apiurl + "/1.0.0/bookOrder",method:"jsonp",async : false},
        		//验证缓存是否失效
        		validateTimeOut: {url:global.apiurl + "/1.0.0/validateTimeOut", method:"jsonp", async : false},
                //查询同行订单
                queryTxOrder:{url:global.apiurl+"/1.0.0/queryTxOrder",method:"get",async:false},
                
                //查询重复订单  （下单后）
                querySameOrder:{url:global.apiurl+"/1.0.0/querySameOrder",method:"get",async:false},
                
                //获取政采票预算单位
                getBudgetUnitList:{url: global.apiurl + "/1.0.0/getBudgetUnitList",method: "jsonp",async:false},
				//获取政采票公务卡银行开户行列表  /1.0.0/getGpBankList
				getGpBankList:{url: global.apiurl + "/1.0.0/getGpBankList",method: "jsonp",async:false},
                //查询重复订单  （生成pnr前）
                querySameOrder2:{url:global.apiurl+"/2.0.0/querySameOrder",method:"jsonp",async:false},

			},
			
			//国际机票接口
			tkIntBook:{
				//POST /1.0.0/queryGjFlight
				//航班查询
//              queryGjFlight:{url:global.apiurl+"/1.0.0/queryGjFlight",method:"jsonp"},
				queryGjFlight:{url:global.apiurl+"/2.0.0/queryGjFlight",method:"jsonp"},
				
				//POST /1.0.0/queryGjFlightAllPrice
				//航班全舱位查询
//              queryGjFlightAllPrice:{url:global.apiurl+"/1.0.0/queryGjFlightAllPrice",method:"jsonp"},
				queryGjFlightAllPrice:{url:global.apiurl+"/2.0.0/queryGjFlightAllPrice",method:"jsonp"},
				
				//POST /1.0.0/queryGjFlightRule
				//查询国际退改签信息
                queryGjFlightRule:{url:global.apiurl+"/1.0.0/queryGjFlightRule",method:"jsonp"},
                //POST /1.0.0/bookGjOrder
				//国际机票下单
                bookGjOrder:{url:global.apiurl+"/1.0.0/bookGjOrder",method:"jsonp"},
					
				//GET /1.0.0/matchVipClzcByGjFlight
				//国际票机票查询 匹配差旅标准
                matchVipClzcByGjFlight:{url:global.apiurl+"/1.0.0/matchVipClzcByGjFlight",method:"get",async:true},
                //超时
                gjValidateTimeOut:{url:global.apiurl+"/1.0.0/gjValidateTimeOut",method:"jsonp",async:true},
                //航司 /1.0.0/findAirLineList
                findAirLineList:{url:global.apiurl+"/1.0.0/findAirLineList",method:"get",async:true},
				
			},
			htbook : {
				//附近酒店信息
				hotelListSearch : { url:global.apiurl + "/1.0.0/ht/fa/hotelListSearch", method:"jsonp",async :true},
				hotelListSearch2 : { url:global.apiurl + "/2.0.0/ht/fa/hotelListSearch", method:"jsonp",async :true},
				// /1.0.0/ht/fa/hotelListCompensate es搜索补偿 酒店列表分屏
				hotelListCompensate : { url:global.apiurl + "/1.0.0/ht/fa/hotelListCompensate", method:"jsonp",async :true},
				//列表最低价
				minPrice : { url:global.apiurl + "/1.0.0/ht/fa/hotelMinPrice", method:"jsonp"},
				// POST /1.0.0/ht/fa/hotelDataValidate 下单验证满房
				hotelDataValidate: {url: global.apiurl + "/1.0.0/ht/fa/hotelDataValidate",method: "jsonp"},
				// POST /1.0.0/ht/fa/hotelCheckMinPrice 最低价校验
				hotelCheckMinPrice: {url: global.apiurl + "/1.0.0/ht/fa/hotelCheckMinPrice",method: "jsonp"},
				//附近交通信息
				poiTraffic : { url:global.apiurl + "/1.0.0/ht/poiTraffic", method:"jsonp",async :true},
				//酒店详情
				hotelDetail : { url:global.apiurl + "/1.0.0/ht/st/hotelDetail", method:"jsonp"},
				//酒店价格计划列表
				planSearch :{url:global.apiurl + "/1.0.0/ht/fa/hotelPlanSearch", method:"jsonp",async:true},
				planSearch2 :{url:global.apiurl + "/2.0.0/ht/fa/hotelPlanSearch", method:"jsonp",async:true},
				//报价缓存
				planToCache :{url:global.apiurl + "/1.0.0/ht/fa/hotelPlanToCache", method:"jsonp"},
				//房型信息
				hotelRoom :{url:global.apiurl + "/1.0.0/ht/st/hotelRoom", method:"jsonp"},
				//担保验证
				guarantee :{url:global.apiurl + "/1.0.0/ht/fa/hotelGuaranteeValid", method:"jsonp"},
				//预订生成订单
				book : {url:global.apiurl + "/2.0.0/ht/fa/hotelBook", method:"jsonp",async:true},
				//预订生成订单
//				book : {url:global.apiurl + "/2.0.0/ht/fa/hotelBook", method:"jsonp",async:true},
				//获取差旅标准
				travelPolicy : {url:global.apiurl + "/1.0.0/travelPolicyDetail", method:"jsonp"},
				travelPolicy2 : {url:global.apiurl + "/2.0.0/travelPolicyDetail", method:"jsonp"},
				//信用卡
				creditCard : {url:global.apiurl + "/1.0.0/ht/fa/hotelCreditCard", method:"jsonp"},
				//展示图片
				hotelImage : {url:global.apiurl + "/1.0.0/ht/fa/imageList", method:"jsonp"},
				//违背原因
				getBClass:{url:global.apiurl + "/1.0.0/getBClass", method:"get"},
				//企业管理（检查企业差旅政策是否开启）
				getConfigInfo:{url:global.apiurl + "/1.0.0/getConfigInfo", method:"get",async:true},
				// POST /1.0.0/ht/fa/isHotelValid酒店是否有效
				isHotelValid: {
					url: global.apiurl + '/1.0.0/ht/fa/isHotelValid',
					method: "jsonp"
				},
			},
			
			//酒店订单管理
			hotelOrder : {
				//酒店列表
				hotelOrderList : {url:global.apiurl + "/1.0.0/hotelOrderList", method:"jsonp"},
				//订单取消
				hotelOrderCancel : {url:global.apiurl + "/1.0.0/hotelOrderCancel", method:"jsonp"},
				//订单详情
				hotelOrderDetail : {url:global.apiurl + "/1.0.0/hotelOrderDetail", method:"get",async:false},
				//酒店询价单列表
				hotelNeedOrderList : {url:global.apiurl + "/1.0.0/hotelNeedOrderList", method:"jsonp"},
				//酒店询价单详情
				getHtNeedOrderDetail : {url:global.apiurl + "/1.0.0/getHtNeedOrderDetail", method:"jsonp"},
				//酒店询价单详情
				findHtNeedNote : {url:global.apiurl + "/1.0.0/findHtNeedNote", method:"jsonp"},
				//保存签注
				savehtNeedNote : {url:global.apiurl + "/1.0.0/savehtNeedNote", method:"jsonp"},
				//新增需简单
				saveNeedOrder : {url:global.apiurl + "/1.0.0/saveNeedOrder", method:"jsonp"},
				
			},
			
			htRefund : {
				//申请退房
				applyHtRefund : {url:global.apiurl + "/1.0.0/applyHotelRefund", method:"jsonp"},
                // htRefundSave : {url:global.apiurl + "/1.0.0/hotelRefundAdd", method:"jsonp"},
                // 退房保存升级到2.0版本
                htRefundSave : {url:global.apiurl + "/2.0.0/hotelRefundAdd", method:"jsonp"},
				//退单详
				htRefundDetail : {url:global.apiurl + "/1.0.0/hotelRefundDetail", method:"jsonp"},
				//取消退房
				cancelHtRefund : {url:global.apiurl + "/1.0.0/hotelRefundCancel", method:"jsonp"},
				//酒店退单列表
				hotelRefundList : {url:global.apiurl + "/1.0.0/hotelRefundList", method:"jsonp"},
			},
			
			//火车票查询预定
			trBook : {
				snapUpSetting : {url:global.apiurl + "/1.0.0/trainCorp/snapUpSetting", method:"jsonp",async:true},

				//火车票查询
				trSearch : {url:global.apiurl + "/1.0.0/trSearch", method:"jsonp",async:true},
				//查询经停站
				queryStation : {url:global.apiurl + "/1.0.0/queryStation", method:"jsonp",async:true},
				//差旅标准匹配
				trBookMatchClzc : {url:global.apiurl + "/1.0.0/trBookMatchClzc", method:"jsonp", async : false},
				//获取账号
				getAccount : {url:global.apiurl + "/1.0.0/getAccount", method:"get", async : false},
				//验证账号
				trCheckAccount : {url:global.apiurl + "/1.0.0/trCheckAccount", method:"jsonp"},
				//火车票下单
				trBookOrder : {url:global.apiurl + "/2.0.0/trBookOrder", method:"jsonp"},
				//火车票抢票下单
				trGradBook : {url:global.apiurl + "/1.0.0/trGradBook", method:"jsonp"},
				//火车票抢票单取消接口
				cancelTrGrad : {url:global.apiurl + "/1.0.0/cancelTrGrad", method:"jsonp"},
				//火车票订单详情
				trOrderDetail : {url:global.apiurl + "/1.0.0/trOrderDetail", method:"get",async:false},
				//取消订单
				trOrderCancel : {url:global.apiurl + "/1.0.0/trOrderCancel", method:"get"},
				//订单列表
				trOrderList : {url:global.apiurl + "/1.0.0/trOrderList", method:"jsonp"},
				//获取12036账号列表
				trAccountGetList : {url:global.apiurl + "/1.0.0/trAccountGetList", method:"get"},
				//删除12306账号
				trAccountDel : {url:global.apiurl + "/1.0.0/trAccountDel", method:"get"},
				
				//火车票下单身份信息核验
				trCheckPassport:{url:global.apiurl + "/1.0.0/trCheckPassport", method:"jsonp"},
				
				// 12306账号登录
				loginAccount:{url:global.apiurl + "/1.0.0/railway/loginAccount", method:"jsonp"},
				// 12306账号登录2.0
				loginAccount2:{url:global.apiurl + "/2.0.0/railway/loginAccount", method:"jsonp"},
				// 12306账号登录
				validateAccount:{url:global.apiurl + "/1.0.0/railway/validateAccount", method:"jsonp"},
				// 注册12306
				registerAccount: {url:global.apiurl + "/1.0.0/railway/registerAccount", method:"jsonp"},
				// 员工绑定12306账号列表
				bindList: {url:global.apiurl + "/1.0.0/railway/bindList", method:"jsonp"},
				// orderAccount 获取订单关联的12306账号信息
				orderAccount: {url:global.apiurl + "/1.0.0/orderAccount", method:"jsonp"},
				//  12306验证短信
				validateMessage: {url:global.apiurl + "/1.0.0/railway/validateMessage", method:"jsonp"},
				// POST /1.0.0/trainCityStation获取当前城市的所有站点
				trainCityStation: {
				  url: global.apiurl + "/1.0.0/trainCityStation",
				  method: "jsonp"
				},
				// 不同的数据结构  
				trainCityStation2: {
				  url: global.apiurl + "/2.0.0/trainCityStation",
				  method: "jsonp"
				}
				
			},
			//火车票改签
			trChange : {
				//获取可改签的乘车人
				applyTrChange: {url:global.apiurl + "/1.0.0/applyTrChange", method:"get"},
				//改签单取消
				cancelTrChangeOrder: {url:global.apiurl + "/1.0.0/cancelTrChangeOrder", method:"get"},
				//根据订单id获取改签单
				findTrChangeOrderByOrderId: {url:global.apiurl + "/1.0.0/findTrChangeOrderByOrderId", method:"get"},
				// //申请改签
				// trChangeOrderAdd : {url:global.apiurl + "/1.0.0/trChangeOrderAdd", method:"jsonp",async:true},
				//申请改签
				trChangeOrderAdd : {url:global.apiurl + "/2.0.0/trChangeOrderAdd", method:"jsonp",async:true},
				//改签单详情
				trChangeOrderDetail : {url:global.apiurl + "/1.0.0/trChangeOrderDetail", method:"get",async:false},
				//火车票订单列表
				trChangeOrderList : {url:global.apiurl + "/1.0.0/trChangeOrderList", method:"jsonp",async:true},
				
				//改签单确认出票
				confirmTrChangeOrder: {url:global.apiurl + "/1.0.0/confirmTrChangeOrder", method:"get"},
			},
			
			// 火车票退票
			trRefund : {
				//火车票申请退票
				applyTrRefund :{url:global.apiurl + "/1.0.0/applyTrRefund", method:"get"}, 
				//火车票取消退票
				cancelTrRefund :{url:global.apiurl + "/1.0.0/cancelTrRefund", method:"get"},
				//火车票退票申请保存1.0
				saveTrRefund :{url:global.apiurl + "/1.0.0/saveTrRefund", method:"jsonp"},
				//火车票退票申请保存2.0
				saveTrRefund2 :{url:global.apiurl + "/2.0.0/saveTrRefund", method:"jsonp"},
				//根据订单id获取退票订单集合
				findTrRefundOrderByIds :{url:global.apiurl + "/1.0.0/findTrRefundOrderByIds", method:"get"},
				//根据订单id获取退票订单集合
				trRefundDetail:{url:global.apiurl + "/1.0.0/trRefundDetail", method:"get"},
				//取消订单
				cancelTrRefund:{url:global.apiurl + "/1.0.0/cancelTrRefund", method:"get"},
			},
			
			adminPath : {url: "/admin/"},
			
			insurance : {
				//获取保险产品
				getBxcpList : {url:global.apiurl + "/1.0.0/getBxcpList", method:"get"},
				//保险下单
				bookBx : {url:global.apiurl + "/1.0.0/bookBx", method:"jsonp"},
				//获取保险订单集合
				getBxOrder : {url:global.apiurl + "/1.0.0/getBxOrder", method:"get"},
				//获取保险订单集合
				getBxOrderListByGlddId : {url:global.apiurl + "/1.0.0/getBxOrderListByGlddId", method:"get"},
				//申请退保POST /1.0.0/applyRefundBx
				applyRefundBx: {url: global.apiurl + "/1.0.0/applyRefundBx",method: "jsonp"},
			},
			//机场服务
			airService:{
				//POST /1.0.0/queryAirService
				queryAirService:{url:global.apiurl + "/1.0.0/queryAirService", method:"jsonp"},
				//POST /1.0.0/bookServiceOrder 机场服务订单预订
				bookServiceOrder:{url:global.apiurl + "/1.0.0/bookServiceOrder", method:"jsonp"},
				//POST /1.0.0/cancelServiceOrder 机场服务取消订单接口
				cancelServiceOrder:{url:global.apiurl + "/1.0.0/cancelServiceOrder", method:"jsonp"},
				//POST /1.0.0/queryServiceOrderDetail 查询机场服务订单详情接口
				queryServiceOrderDetail:{url:global.apiurl + "/1.0.0/queryServiceOrderDetail", method:"jsonp"},
				//POST /1.0.0/queryServiceOrderList 查询机场服务订单列表接口
				queryServiceOrderList:{url:global.apiurl + "/1.0.0/queryServiceOrderList", method:"jsonp"},
				//GET /1.0.0/queryWcxOrder 获取存在本系统的未出行或者未到达的订单
				queryWcxOrder:{url:global.apiurl + "/1.0.0/queryWcxOrder", method:"get"},
				//通过机场三字码，查航站楼
				queryApTrnInfo:{url:global.apiurl + "/1.0.0/queryApTrnInfo", method:"jsonp"},
				//休息室退单详情
				queryAirportServiceRefundDetail:{url:global.apiurl + "/1.0.0/queryAirportServiceRefundDetail", method:"jsonp"},
				//休息室提交退单
				airportServiceRefundAdd:{url:global.apiurl + "/1.0.0/airportServiceRefundAdd", method:"jsonp"},
				//机场服务退单详情
				queryAirportServiceRefundDetail:{url:global.apiurl + "/1.0.0/queryAirportServiceRefundDetail", method:"jsonp"},
				//机场服务退单列表查询接口
                queryAirportServiceRefundList:{url:global.apiurl + "/1.0.0/queryAirportServiceRefundList", method:"jsonp"},
                	//查询机场服务使用记录
				airportServiceOrderUseList:{url:global.apiurl + "/1.0.0/airportServiceOrderUseList", method:"jsonp"},
			},
			//国际需求单
			tkInter:{
				// 国际需求单生成订单 /1.0.0/tkNeedOrderAdd
				tkNeedOrderAdd:{url:global.apiurl + "/1.0.0/tkNeedOrderAdd", method:"jsonp"},
				// 国际需求单获取详情
				tkNeedOrderDetail:{url:global.apiurl + "/1.0.0/tkNeedOrderDetail", method:"jsonp"},
				// 取消订单
				tkNeedOrderCancel:{url:global.apiurl + "/1.0.0/tkNeedOrderCancel", method:"jsonp"},
				// 订单列表
				tkNeedOrderList:{url:global.apiurl + "/1.0.0/tkNeedOrderList", method:"jsonp"},
				// 保存签注
				savehtNeedNote:{url:global.apiurl + "/1.0.0/savehtNeedNote", method:"jsonp"},
				// 查询签注
				findHtNeedNote:{url:global.apiurl + "/1.0.0/findHtNeedNote", method:"jsonp"},
				// 
			},
			//海外酒店需求单
			htOuter:{
				//酒店列表
                interHotelList:{url:global.apiurl + "/1.0.0/ht/interHotelList", method:"jsonp"},
                //酒店价格详情
                interHotelPrice:{url:global.apiurl + "/1.0.0/ht/interHotelPrice", method:"jsonp"},
                //获取酒店图片列表
                interHotelImage:{url:global.apiurl + "/1.0.0/ht/interHotelImage", method:"jsonp"},
                //国籍列表
                interCountryList:{url:global.apiurl + "/1.0.0/ht/interCountryList", method:"jsonp"},
                //酒店详情
                interHotelDetail:{url:global.apiurl + "/1.0.0/ht/interHotelDetail", method:"jsonp"},
                //国际酒店单酒店价格确认接口
                interPriceConfirm:{url:global.apiurl + "/1.0.0/ht/interPriceConfirm", method:"jsonp"},
                //国际酒店差旅政策查询
                interHotelTravelPolicy:{url:global.apiurl + "/1.0.0/interHotelTravelPolicy", method:"jsonp"},
                //国际酒店下单
                interHotelBook:{url:global.apiurl + "/1.0.0/ht/interHotelBook", method:"jsonp"},
                //国际酒店关键字搜索
                interHtKeyWordSearch:{url:global.apiurl + "/1.0.0/ht/interHtKeyWordSearch", method:"jsonp"},
                //获取国际酒店取消罚金
                interHotelOrderCancel:{url:global.apiurl + "/1.0.0/ht/interHotelOrderCancel", method:"jsonp"},
                //国际酒店确认取消
                interHotelOrderCancelConfirm:{url:global.apiurl + "/1.0.0/ht/interHotelOrderCancelConfirm", method:"jsonp"},
				// 保存下单
				saveNeedOrder:{url:global.apiurl + "/1.0.0/saveNeedOrder", method:"jsonp"},
				// 
				getHtNeedOrderDetail:{url:global.apiurl + "/1.0.0/getHtNeedOrderDetail", method:"jsonp"}
				
			},
			//用车
			carBook:{
				//用车获取取消订单违约金接口
				getYcCancelFee:{url: global.apiurl + "/1.0.0/getYcCancelFee",method: "jsonp"},
				//POST /1.0.0/queryDayCar  日包查询
				queryDayCar:{url:global.apiurl + "/1.0.0/queryDayCar", method:"jsonp"},
				//POST /1.0.0/bookCarOrder   用车下单
				bookCarOrder:{url:global.apiurl + "/2.0.0/bookCarOrder", method:"jsonp"},
				//获取用车城市列表
				getCityList:{url: global.apiurl + "/1.0.0/vehiclesCityFindList",method: "jsonp"},
				//POST /1.0.0/queryCarOrderList   查询订单列表
				queryCarOrderList:{url: global.apiurl + "/1.0.0/queryCarOrderList",method: "jsonp"},
				//GET /1.0.0/QueryCarOrderDetail    用车订单详情
				queryCarOrderDetail:{url: global.apiurl + "/1.0.0/queryCarOrderDetail",method: "jsonp"},
				//GET /1.0.0/cancelCarOrder    取消用车订单
				cancelCarOrder:{url: global.apiurl + "/1.0.0/cancelCarOrder",method: "jsonp"},
				
				//POST /1.0.0/queryCar 			线路包车查询
				queryCar:{url: global.apiurl + "/1.0.0/queryCar",method: "jsonp"},
				//根据航班号获取航班信息
				queryFlightByHbh:{url: global.apiurl + "/1.0.0/queryFlightByHbh",method: "jsonp"},
				//1.0.0/baidu/geocoder			经纬度解析城市数据
				getCityInfo:{url: global.apiurl + "/1.0.0/baidu/geocoder",method: "jsonp"},
				// 用车差旅政策
				vipCarClzcList:{url: global.apiurl + "/1.0.0/vipCarClzcList",method: "jsonp"}
				
			},
			// 泊车
			park:{
				//  getParkPrdList   获取产品列表
				getParkPrdList:{url:global.apiurl + "/1.0.0/getParkPrdList", method:"jsonp"},
				//  parkOrderDetail   订单详情 
				parkOrderDetail:{url:global.apiurl + "/1.0.0/parkOrderDetail", method:"get"},
				//  下订单
				bookParkingOrder:{url:global.apiurl + "/1.0.0/bookParkingOrder", method:"jsonp"},
				//  取消订单
				cancelParkOrder:{url:global.apiurl + "/1.0.0/cancelParkOrder", method:"get"},
				//  订单列表
				parkOrderList:{url:global.apiurl + "/1.0.0/parkOrderList", method:"jsonp"},
			},
			dbzj:{
				//代办值机下单
				bookCheckInOrder:{url:global.apiurl + "/1.0.0/bookCheckInOrder", method:"jsonp"},
				//获取可进行代办值机的机票订单
				getTkOrder:{url:global.apiurl + "/1.0.0/getTkOrder", method:"jsonp"},
				//获取代办值机产品
				queryCheckInAgent:{url:global.apiurl + "/1.0.0/queryCheckInAgent", method:"jsonp"},
				//验证乘机人是否可以免费使用服务
				validateCanFree:{url:global.apiurl + "/1.0.0/validateCanFree", method:"jsonp"},
				//获取可以代办值机的机场
				getCheckinAirport:{url:global.apiurl + "/1.0.0/getCheckinAirport", method:"get"},
				//代办值机订单详情
				getCheckInDeatils:{url:global.apiurl + "/1.0.0/getCheckInDeatils", method:"jsonp"},
				//获取代办值机订单列表
				getCheckInList:{url:global.apiurl + "/1.0.0/getCheckInList", method:"jsonp"},
				//取消代办值机订单
				checkInOrderCancel:{url:global.apiurl + "/1.0.0/checkInOrderCancel", method:"jsonp"},
			},


            xakCcsqd:{
                xakVipCcsqd:{ //希安康出差申请单
                    url: global.apiurl + "/1.0.0/fastBookCcsqdSave",
                    method: "jsonp"
                },
                xakVipCcsqdGetInfo: { // 希安康出差申请单详情
                    url: global.apiurl + "/1.0.0/fastBookCcsqdDetails",
                    method: "jsonp"
                },
                // 希安康变更  /2.0.0/fastBookChangeSave 快捷预订出差申请单变更
                fastBookChangeSave:{
                    url: global.apiurl + "/2.0.0/fastBookChangeSave",
                    method: "jsonp"
                },
                // 希安康重新提交 /1.0.0/resetFastBookCcsqd 重新生成出差申请单
                resetFastBookCcsqd:{
                    url: global.apiurl + "/1.0.0/resetFastBookCcsqd",
                    method: "jsonp"
                },
                fastBookChangeList: { // 变更单列表
                    url: global.apiurl + "/1.0.0/fastBookChangeList",
                    method: "jsonp"
                },
                fastBookChangeDetails:{
                    url: global.apiurl + "/2.0.0/fastBookChangeDetails",
                    method: "jsonp"
                },
                ClockInDetails: { // 打卡详情
                    url: global.apiurl + "/1.0.0/routeClockInDetails",
                    method: "jsonp"
                },
                routeClockIn: { // 打卡
                    url: global.apiurl + "/1.0.0/routeClockIn",
                    method: "jsonp"
                },
                simpleBookOrder: { //机票下单
                    url: global.apiurl + "/1.0.0/simpleBookOrder",
                    method: "jsonp"
                },
                        //trBookOrder   火车票下单
                trBookOrder: {
                    url: global.apiurl + "/3.0.0/trBookOrder",
                    method: "jsonp"
                },
                hotelBook:{
                    url: global.apiurl + "/2.0.0/ht/fa/hotelBook",
                    method: "jsonp"
                },
                hotelCheckAvail:{
                    url: global.apiurl + "/1.0.0/ht/fa/hotelCheckAvail", //酒店订单填写验证价格跟库存
                    method: "jsonp"
                },
                fastBookCcsqdCancelService:{
                    url: global.apiurl + "/1.0.0/fastBookCcsqdCancelService",
                    method: "jsonp"
                },
                findCcrCcsqdSetting:{
                    url: global.apiurl + "/1.0.0/findCcrCcsqdSetting",
                    method: "jsonp"
                },
                getProvinceInfo:{url:global.apiurl +"/1.0.0/getProvinceInfo", method:"jsonp"}, // 获取省份
    
                getCityInfo:{url:global.apiurl +"/1.0.0/getCityInfo", method:"jsonp"},  // POST /1.0.0/getCityInfo 获取城市数据
    
                getLocationInfo:{url:global.apiurl +"/1.0.0/getLocationInfo", method:"jsonp"},            // POST /1.0.0/getLocationInfo 获取区数据
				
				// /1.0.0/createCcsqdPdf?id= 返回的文件流
				createCcsqdPdf:{
				    url: global.apiurl + "/1.0.0/createCcsqdPdf",
				    method: "get"
				},
				testcreateCcsqdPdf:{
				    url: global.apiurl + "/test/createCcsqdPdf",
				    method: "get"
				},
				// /2.0.0/createCcsqdPdf?id= 返回的base64的文件字符
				createCcsqdPdf2:{
				    url: global.apiurl + "/2.0.0/createCcsqdPdf",
				    method: "get"
				},
				
			
			},
				// ************************ 积分 **************************
			intergral: {
				// 	POST /1.0.0/htAmountPoints 通过酒店支付金额获取积分
				htAmountPoints: {
					url: global.apiurl + '/1.0.0/htAmountPoints',
					method: "jsonp"
				},
			},
	        // ************************ 会议 **************************
            mice:{
                // POST /1.0.0/mice/caseshow/page会议会展案例展
                caseshowPage:{url: global.apiurl + '/1.0.0/mice/caseshow/page',method: "jsonp"},
                // POST /1.0.0/mice/caseshow/page会议会展案例展
                // caseshowPage:{url: global.apiurl + '/1.0.0/mice/caseshow/page',method: "jsonp"}
            },

		dzd: {
			// POST /1.0.0/dzdOnline 在线对账单列表
			dzdOnline: {url: global.apiurl + '/1.0.0/dzdOnline', method: "jsonp", noNeedToken: true,},
			// POST /1.0.0/dzdOnlineConfirm 在线对账单确认
			dzdOnlineConfirm: {url: global.apiurl + '/1.0.0/dzdOnlineConfirm', method: "jsonp", noNeedToken: true,},
			// POST /1.0.0/dzdOnlineExport 在线对账单列表导出
			dzdOnlineExport: {url: global.apiurl + '/1.0.0/dzdOnlineExport', method: "jsonp", noNeedToken: true,},
		}
	};


export default conn